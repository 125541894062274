import { useStaticQuery, graphql } from "gatsby"

const MenuItems = (props) => {
  const { name, children } = props

  const { allWpMenuItem } = useStaticQuery(graphql`
    {
      allWpMenuItem(sort: { order: ASC, fields: order }) {
        nodes {
          id
          label
          url
          menu {
            node {
              name
            }
          }
          cssClasses
          parentDatabaseId
          menuBanner {
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 300, layout: CONSTRAINED)
                }
              }
            }
          }
          childItems {
            nodes {
              id
              label
              url
              cssClasses
              childItems {
                nodes {
                  id
                  label
                  url
                  cssClasses
                  childItems {
                    nodes {
                      id
                      label
                      url
                      cssClasses
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let items = []

  if (typeof name === "string" || name instanceof String) {
    items = allWpMenuItem.nodes.filter(
      (o) => o?.menu?.node?.name === `${name}-${process.env.GATSBY_ACTIVE_LANGUAGE.toLowerCase()}`
    )
  } else {
    name.map((s) => {
      const itemsByName = allWpMenuItem.nodes.filter(
        (o) => o?.menu?.node?.name === `${s}-${process.env.GATSBY_ACTIVE_LANGUAGE.toLowerCase()}`
      )

      return (items = items.concat(itemsByName))
    })
  }

  return items ? children(items) : null
}

export default MenuItems

import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import { Typography, Dialog, IconButton } from "@material-ui/core"
import Button from "../button"

import { Close as CloseIcon } from "react-ionicons"

import { useStore } from "../../store"

import Edges from "../edges"
import GeneralSurveyForm from "./GeneralSurveyForm"

const storageKey = "realcedar-general-survey-data"

const GeneralSurvey = (props) => {
  const {
    location: { pathname }
  } = props

  const [
    {
      appState: {
        generalSurvey: { open }
      }
    },
    dispatch
  ] = useStore()

  const [state, setState] = useState("asking-for-feedback")

  const [localItem, setLocalItem] = useLocalStorage(storageKey)

  const handleClose = () => {
    dispatch({ type: "SET_GENERAL_SURVEY", payload: { open: false } })
    setLocalItem({ ...localItem, completed: true })
  }

  const incrementPageViewCount = async () => {
    if (localItem?.pageViewCount <= 3 || !localItem) {
      setLocalItem({ ...localItem, pageViewCount: (localItem?.pageViewCount || 0) + 1 })
    }
  }

  useEffect(() => {
    // whenever page path changes, increase pageViewCount
    incrementPageViewCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (localItem?.pageViewCount > 3 && !localItem?.completed && !open) {
      // qualified, open the survey
      dispatch({ type: "SET_GENERAL_SURVEY", payload: { open: true } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localItem])

  const handleSuccess = () => {
    // set survey completed in localStorage in case the user navigates away or refreshes after completion
    setLocalItem({ ...localItem, completed: true })
    setState(`success`)
  }

  const [startingTime, setStartingTime] = useState(null)

  useEffect(() => {
    if (state === `showing-survey-form`) {
      setStartingTime(new Date().getTime())
    }
  }, [state])

  return (
    <Container open={open} onClose={handleClose} fullWidth state={state}>
      <TopBar state={state}>
        <IconButton
          onClick={handleClose}
          css={css`
            position: relative;
            z-index: 1;
            top: 0;
            right: 15px;
          `}
        >
          <CloseIcon />
        </IconButton>
      </TopBar>
      <Edges size="lg">
        {state === "asking-for-feedback" ? (
          <div
            css={css`
              padding: 30px 0;
            `}
          >
            <Typography variant="h2" gutterBottom>
              We'd welcome your feedback.
            </Typography>
            <Typography gutterBottom>
              This survey is designed to help improve the experience of RealCedar.com users like you.
            </Typography>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
              `}
            >
              <Button onClick={handleClose}>No thanks</Button>
              <Button onClick={() => setState("showing-survey-form")}>Yes, I'll give feedback</Button>
            </div>
          </div>
        ) : state === "showing-survey-form" ? (
          <>
            <GeneralSurveyForm onSuccess={handleSuccess} startingTime={startingTime} />
          </>
        ) : (
          state === "success" && (
            <div
              css={css`
                padding: 30px 0;
              `}
            >
              <Message success>
                <Typography>
                  Thank you for your feedback! Your input is extremely valuable to helping us improve the resources we
                  provide to Realcedar.com visitors like you.
                </Typography>
              </Message>
            </div>
          )
        )}
      </Edges>
    </Container>
  )
}

const TopBar = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  margin-left: auto;
  max-width: ${({ state }) => (state === "asking-for-feedback" ? "600px" : "800px")};
  width: calc(100% - 64px);
`

const Container = styled(Dialog)`
  .MuiPaper-root {
    position: relative;
  }
  .MuiDialog-paperWidthSm {
    max-width: ${({ state }) => (state === "asking-for-feedback" ? "600px" : "800px")};
  }

  .field--html,
  .field--radio {
    width: 100%;
  }

  .radio-buttons-horizontal {
    > .control-label {
      margin-bottom: 10px;
    }

    > .field-radio-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > .radio-inline {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > .radio-inline > span {
        margin-right: 0;
      }

      > .radio-inline > span > span {
        text-align: center;
        margin: 0 5px;
      }
    }

    .field-radio-group .radio-inline > span {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
    }
  }
`

const Message = styled.div`
  width: 100%;
  border: 1px solid ${(props) => (props.success ? "#2dad38" : "#e24141")};
  padding: 10px 14px;
  margin-top: 20px;
`

const useLocalStorage = (key) => {
  const isBrowser = typeof window !== `undefined`

  if (!isBrowser) {
    return [null, null]
  }

  const setLocalItem = (data) => {
    const dataString = JSON.stringify(data)
    localStorage.setItem(key, dataString)
  }

  const localItem = localStorage.getItem(key)
  const result = localItem && JSON.parse(localItem)

  return [result, setLocalItem]
}

export default GeneralSurvey

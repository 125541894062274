import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled, { css } from "styled-components"

import { getUrlPath } from "utils/getUrlPath"

const wpUrl = process.env.GATSBY_WP

const isUrlExternal = link => {
  if (!link || link === null || link === undefined) {
    return undefined
  } else if (!link.includes(wpUrl) && link.includes("http")) {
    return true
  } else {
    return false
  }
}

const Link = ({ to, target, ...rest }) => {
  if (!to || to === null || to === 0) {
    return <NonLink {...rest} />
  } else if (isUrlExternal(to)) {
    return <ExternalLink href={to} target="_blank" rel="noopener" {...rest} />
  } else if (!isUrlExternal(to)) {
    return <InternalLink to={decodeURI(getUrlPath(to))} {...rest} />
  } else return null
}

export default Link

const linkStyle = css`
  text-decoration: none;
  color: inherit;
`
const InternalLink = styled(GatsbyLink)`
  ${linkStyle}
`
const ExternalLink = styled.a`
  ${linkStyle}
`
const NonLink = styled.div`
  ${linkStyle}
  cursor: default;
`

import React from "react"
import Parser from "html-react-parser"
import styled from "styled-components"

import * as theme from "theme"

const Textarea = (props) => {
  const { content, ...rest } = props

  if (typeof content === "string" || content instanceof String) {
    let contentString = content
      .toString()
      .trim()
      // remove default html tags, inserted by cherio js
      .replace("<html>", "")
      .replace("</html>", "")
      .replace("<head>", "")
      .replace("</head>", "")
      .replace("<body>", "")
      .replace("</body>", "")
      // remove line breaks to fix table errors
      .replace(/(\r\n|\n|\r)/gm, "")
      // wrap table into div to make it responsive
      .replace(/<table/g, "<div class='table-wrapper'><table")
      .replace(/\/table>/g, "/table></div>")
      .replace(/<iframe/g, "<div class='iframe-wrapper'><iframe")
      .replace(/\/iframe>/g, "/iframe></div>")

    contentString = updateLinksInHTML(contentString)

    return <Container {...rest}>{Parser(contentString)}</Container>
  } else {
    return null
  }
}

const p = { ...theme.mui.typography.body1 }

const Container = styled.div`
  && {
    clear: both;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    /* > * {
      margin-bottom: 20px;
    } */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 24px;
    }

    ol,
    ul,
    figcaption,
    div {
      margin-bottom: 18px;
    }

    a {
      text-decoration: underline;
      font-style: italic;
      font-weight: 500;
      color: ${theme.colors.darkGreen};
    }

    figcaption {
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }

    ul,
    ol {
      margin-left: 25px;
    }
    ol {
      list-style-type: decimal;
    }
    ol li {
      color: #4c656c !important;
    }

    ul li {
      list-style: disc;
    }

    li ul {
      margin-bottom: 14px;
    }

    li ul li:first-child {
      margin-top: 14px;
    }

    img {
      margin-bottom: 20px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;

      &.alignright {
        float: right;
        margin-left: 20px !important;
      }

      &.alignleft {
        float: left;
        margin-right: 20px !important;
      }

      &.size-medium {
        width: 300px;
      }

      &.size-thumbnail {
        width: 150px;
      }

      &.size-full {
      }

      &.fullwidth {
        width: 100%;
      }

      @media (max-width: 420px) {
        width: 100% !important;
      }
    }

    .inline-block {
      img {
        @media (max-width: 420px) {
          width: auto !important;
        }
      }
    }

    .table-wrapper {
      width: 100%;
      max-width: 100%;
      flex: 1;
      overflow-x: auto;
    }

    table {
      ${p}
      border-collapse: separate;
      border-spacing: 2px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 1.6rem;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      margin-bottom: 1.6rem;
      font-size: 1rem;
      line-height: 1.6rem;
      border-collapse: collapse;
      width: 100%;
      background: ${theme.colors.lightgrey};
    }
    td {
      display: table-cell;
      vertical-align: inherit;
    }
    tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
    }
    td,
    th {
      text-align: left;
      border: 1px solid #e0e0e0;
      font-feature-settings: "tnum";
      -moz-font-feature-settings: "tnum";
      -ms-font-feature-settings: "tnum";
      -webkit-font-feature-settings: "tnum";
      padding-left: 1.06667rem;
      padding-right: 1.06667rem;
      padding-top: 0.8rem;
      padding-bottom: calc(0.8rem - 1px);
    }
    th:first-child,
    td:first-child {
      padding-left: 0.8rem;
    }

    .iframe-wrapper {
      overflow: hidden;
      // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
      padding-top: 56.25%;
      position: relative;
    }

    .iframe-wrapper iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`

export default Textarea

function updateLinksInHTML(html) {
  const languageCode = new RegExp(`/${process.env.GATSBY_ACTIVE_LANGUAGE.toLowerCase()}/`, "gi")
  const newHTML = html.replace(languageCode, "/")

  return newHTML
}
